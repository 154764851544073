import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createContext, FC, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { useTranslation } from "react-i18next";
import { Marker, NaverMap, Polygon } from "react-naver-maps";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Common/Button/Button2";
import RenderMap from "../../Common/Map/RenderMap";
import { dataAction } from "../../Redux/Data/Data.slice";
import {
  ServiceArea
} from "../../Redux/ServiceArea/ServiceArea.slice";
import { rideAction } from "../../Redux/Trip/Trip.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import ScooterDetail from "./Component/Modal/ScooterDetail.modal";
import {
  formatServiceArea,
  OrderingType,
  saveTripData,
  SearchContextInterface,
  SelectedOptionType,
  TripPagePropType
} from "./util/Trip.util";
export const SearchContext = createContext<SearchContextInterface>({
  selectedOption: {},
  setSelectedOption: () => {},
});

const win: any = window;
const navermaps = win?.naver?.maps;
const TripPage: FC<TripPagePropType> = () => {
  const { t } = useTranslation();
  let mapRef: any = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedScooter, setSelectedScooter] = useState<any>()
  const { paymentData, scooter } = useSelector((state: any) => state.data);
  const { driving } = useSelector((state: any) => state.location);
  const { rideStart } = useSelector((state: any) => state.trip);
  const { noAuthServiceArea } = useSelector((state: any) => state.serviceArea);
  const [selectedOption, setSelectedOption] = useState<SelectedOptionType>({});
  const { scooters } = useSelector((state: any) => state.scooter);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
        timeout: Infinity,
      },
      watchPosition: true,
      isOptimisticGeolocationEnabled: true,
    });

  const handleTripStart = () => {
    dispatch(
      rideAction.rideStart({
        serialNumber: localStorage.getItem("scooter"),
        cardId: paymentData?.card_id,
      })
    )
      .then(({ payload }: any) => {
        if (payload?.data?.errorCode === 0) {
          saveTripData(
            {
              user: [coords?.longitude, coords?.latitude],
              selected: [selectedOption.longitude, selectedOption.latitude],
            },
            driving?.data
          );
          dispatch(
            dataAction.setOrderingData({
              startTime: payload?.data?.data?.start_time,
              ordering: payload?.data?.data?.ordering_id,
              scooterId: payload?.data?.data?.scooter_id,
              type: OrderingType.new,
            })
          );
          dispatch(
            dataAction.setTripData({
              driving: driving?.data ?? {},
              selected: selectedOption,
              location: coords,
            })
          );
          localStorage.setItem("is_done", "true");
          localStorage.setItem("ordering", "true");
          navigate("/end-trip", { replace: true });
        } else {
          // handleLogout();
          navigate("/welcome", { replace: true });
          dispatch(
            snackActions.show({
              message: payload?.data?.message ?? "Ride start failed",
              type: "error",
            })
          );
        }
      })
      .catch((err: any) => {
        dispatch(
          snackActions.show({ message: err?.message ?? "Ride start failed", type: "error" })
        );
      });
  };

  return (
    <SearchContext.Provider
      value={{
        selectedOption,
        setSelectedOption,
      }}
    >
      <div className="h-full w-full">
        <div
          style={{
            position: "absolute",
            bottom: 10,
            zIndex: 1,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Button
            label={t("trip.start_trip")}
            loading={rideStart?.loading}
            handleClick={handleTripStart}
            color="#FA4E3E"
            width="80%"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#000",
            zIndex: 0,
          }}
        >
          <RenderMap
            isGeolocationAvailable={isGeolocationAvailable}
            isGeolocationEnabled={isGeolocationEnabled}
            coords={coords}
          >
            <div>
              <NaverMap
                naverRef={(ref: any) => {
                  mapRef = ref;
                }}
                style={{
                  width: "100%",
                  height: "100vh",
                }}
                defaultCenter={{
                  lat: scooter?.lat ?? coords?.latitude,
                  lng: scooter?.lon ?? coords?.longitude,
                }}
                defaultZoom={15}
                zoom={15}
                logoControl={false}
              >
                <Marker
                  position={
                    new navermaps.LatLng(coords?.latitude, coords?.longitude)
                  }
                  title="Your location"
                />

                {scooters?.data?.errorCode === 0 &&
                  (scooters?.data?.data ?? []).map((scooterItem: any) => (
                    <ScooterDetail
                      scanned={scooter?.serial_number ?? ""}
                      scooter={scooterItem}
                      setSelectedScooter={setSelectedScooter}
                      selectedScooter={selectedScooter}
                    />
                  ))}

                {selectedOption?.latitude && (
                  <Marker
                    position={
                      new navermaps.LatLng(
                        selectedOption?.latitude,
                        selectedOption?.longitude
                      )
                    }
                    title={selectedOption?.location}
                  />
                )}

                <Polygon
                  paths={[
                    [
                      new navermaps.LatLng(90, -180),
                      new navermaps.LatLng(90, 180),
                      new navermaps.LatLng(-90, 180),
                      new navermaps.LatLng(-90, -180),
                    ],
                    ...formatServiceArea(noAuthServiceArea).type0serviceAreaArr,
                  ]}
                  fillColor={"#000000"}
                  fillOpacity={0.6}
                  strokeColor="#fff"
                  strokeOpacity={0.6}
                  strokeWeight={3}
                />

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 5)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#8A7FFF"}
                        fillOpacity={0.6}
                        strokeColor="#8A7FFF"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: `${area.description}`,
                              type: "error",
                              sub: `${area?.amount ?? 0} 원 추가`,
                              isCustom: true,
                              color: "#8A7FFF",
                            })
                          );
                        }}
                      />
                    );
                  })}

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 6)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#EB7B20"}
                        fillOpacity={0.6}
                        strokeColor="#EB7B20"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: area.description,
                              type: "error",
                              isCustom: true,
                              color: "#EB7B20",
                            })
                          );
                        }}
                      />
                    );
                  })}

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 1)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#E84725"}
                        fillOpacity={0.6}
                        strokeColor="#E84725"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: area.description,
                              type: "error",
                              isCustom: true,
                              color: "#E84725",
                            })
                          );
                        }}
                      />
                    );
                  })}
              </NaverMap>
            </div>
          </RenderMap>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={noAuthServiceArea?.loading || scooters?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SearchContext.Provider>
  );
};

export default TripPage;
