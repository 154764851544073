import { FC } from "react";
import OtpInput from "react-otp-input";
import {CodeInputPropType} from "./util/CodeInput.util"

const CodeInput: FC<CodeInputPropType>  = ({code, setCode}) => {
  return (
    <OtpInput
      value={code}
      onChange={(val: string) => setCode(val)}
      numInputs={6}
      separator={<span className="w-2"> </span>}
      // containerStyle={{}}
      inputStyle={{
        width: 40,
        height: 50,
        // marginLeft: 10,
        borderWidth: 3,
        borderRadius: 6,
        borderColor: "#D8D8D8",
        backgroundColor: "#EFEFEF",
      }}
      focusStyle={{
        borderColor: "#999999"
      }}
      shouldAutoFocus={true}
    />
  );
};

export default CodeInput;
