import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../Util/axios.util";

const name: string = "parking";

const createExtraActions: any = () => {
  return {
    addParking: addParking(),
    reset: reset(),
  };

  function addParking() {
    return createAsyncThunk(`${name}/parking`, async (data) =>
      axios.axiosInstance.post(name, data)
    );
  }


  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...addParking(),
    ...reset()
  };

  function addParking() {
    const { pending, fulfilled, rejected }: any = extraActions.addParking;
    return {
      [pending]: (state: any) => {
        state.parking = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.parking = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.parking = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.parking = {};
      },
    };
  }


};

const initialState: any = {
    parking: {},
};


const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const parkingSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const parkingAction = { ... parkingSlice.actions, ...extraActions };
export const parkingReducer = parkingSlice.reducer;
