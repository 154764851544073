import { createAction, createSlice } from "@reduxjs/toolkit";

const createExtraActions: any = () => {
  return {
    setSelectedLocation: setSelectedLocation(),
    setPaymentData: setPaymentData(),
    setScooterData: setScooterData(),
    setUserLocation: setUserLocation(),
    setTripData: setTripData(),
    setOrderingData: setOrderingData(),
    setServiceAreaData: setServiceAreaData(),
    setScooterList: setScooterList(),
    reset: reset(),
  };

  function setSelectedLocation() {
    return createAction(`${name}/setSelectedLocation`);
  }

  function setPaymentData() {
    return createAction(`${name}/paymentData`)
  }

  function setScooterData() {
    return createAction(`${name}/scooter`)
  }

  function setUserLocation() {
    return createAction(`${name}/userLocation`)
  }

  function setTripData() {
    return createAction(`${name}/trip`)
  }

  function setOrderingData() {
    return createAction(`${name}/ordering`)
  }

  function setServiceAreaData() {
    return createAction(`${name}/service-area`)
  }

  function setScooterList() {
    return createAction(`${name}/service-list`)
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...setSelectedLocation(),
    ...setPaymentData(),
    ...setScooterData(),
    ...setUserLocation(),
    ...setTripData(),
    ...setOrderingData(),
    ...setServiceAreaData(),
    ...setServiceList(),
    ...reset()
  };

  function setSelectedLocation() {
    return {
        [extraActions.setSelectedLocation]: (state: any, action: any) => {
          state.selectedLocation = action.payload.name;
        },
      };
  }

  function setPaymentData() {
    return {
        [extraActions.setPaymentData]: (state: any, action: any) => {
          state.paymentData = action.payload;
        },
      };
  }

  function setScooterData() {
    return {
        [extraActions.setScooterData]: (state: any, action: any) => {
          state.scooter = action.payload;
        },
      };
  }

  function setUserLocation() {
    return {
        [extraActions.setUserLocation]: (state: any, action: any) => {
          state.location = action.payload;
        },
      };
  }

  function setTripData() {
    return {
        [extraActions.setTripData]: (state: any, action: any) => {
          state.trip = action.payload;
        },
      };
  }

  function setOrderingData() {
    return {
        [extraActions.setOrderingData]: (state: any, action: any) => {
          state.ordering = action.payload;
        },
      };
  }

  function setServiceAreaData() {
    return {
        [extraActions.setOrderingData]: (state: any, action: any) => {
          state.serviceArea = action.payload;
        },
      };
  }

  function setServiceList() {
    return {
        [extraActions.setOrderingData]: (state: any, action: any) => {
          state.serviceList = action.payload;
        },
      };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.selectedLocation = {};
        state.serviceArea = {}
        state.serviceList = {}
      },
    };
  }


};

const initialState: any = {
  initiate: {},
  paymentData: {},
  scooter: {},
  location: {},
  trip: {},
  ordering: {}
};
const name: any = "data";
const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const dataSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const dataAction = { ...dataSlice.actions, ...extraActions };
export const dataReducer = dataSlice.reducer;
