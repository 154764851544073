import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useEffect, useState, FC } from "react";
import Button from "../Button/Button";
import { CodeTimeoutPropType } from "./util/CodeTimeout.util";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#A69F9F" : "red",
  },
}));
const CodeTimeOut: FC<CodeTimeoutPropType> = ({ resend }) => {
  const {t} = useTranslation();
  const [progress, setProgress] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (progress >= 100) {
      setIsRunning(false);
    }
  }, [progress]);

  useEffect(() => {
    let timer: any;
    if (isRunning) {
      timer = setInterval(() => {
        setProgress((prev) => prev + 10 / 6);
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRunning]);

  return (
    <div>
      <BorderLinearProgress
        className="mt-8"
        variant="determinate"
        value={progress}
      />
      <div className="flex flex-row justify-between pt-2">
        <div>
          {!isRunning && seconds === 0 && (
            <div className="text-[13px]">
              {t("verify.didnt_get_code")}{" "}
              <span className="text-main font-bold cursor-pointer" onClick={() => resend()}>
                {t("verify.resend")}
              </span>
            </div>
          )}
        </div>
        <div className="text-[13px]">
          {seconds} {t("verify.sec")}{seconds === 1 ? "" : ""}
        </div>
      </div>
    </div>
  );
};

export default CodeTimeOut;
