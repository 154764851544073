import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SuccessfulPropType } from "./util/Successful.util";

const SuccessfulPage: FC<SuccessfulPropType> = () => {
  const {t} = useTranslation();
  return (
    <div className="text-center pt-36">
      <TaskAltIcon
        className="text-main"
        sx={{ fontSize: "80px", color: "#fa4e3e" }}
      />
      <div className="flex flex-col justify-center text-md mt-8">
        <p>{t("success.thank_you")}</p>
        <p>{t("success.payment_successful")}</p>
      </div>
      <div className="mt-36">
        <p>{t("success.again")}</p>
      </div>
    </div>
  );
};

export default SuccessfulPage;
