import { AsyncThunk } from "@reduxjs/toolkit";
import { PromiseResult } from "aws-sdk/lib/request";
import credentials from "../../Config/credentials.config";
import { getUserData } from "../../Pages/Verify/util/Verfiy.util";

export interface IUserAction {
  initiate: AsyncThunk<
    PromiseResult<AWS.EC2.DescribeRegionsResult, AWS.AWSError>,
    void,
    {}
  >;
}

export interface Itest {}

export const UserInfoHeader = (): any => {
  const { idToken } = getUserData();
  return {
    headers: {
      Authorization: idToken,
      "x-api-key": credentials.keyId,
    },
  };
};

export const BillingInfoHeader = (data: any): any => {
  const { idToken } = getUserData();
  return {
    headers: {
      Authorization: idToken,
      "x-api-key": credentials.keyId,
      card_number: "4181-4380-1358-9630",
      expiry: "2023-12",
      birth: "870217",
    },
  };
};
