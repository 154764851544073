import { Box, Grid } from "@mui/material";
import { FC, useState } from "react";
import Button2 from "../../Common/Button/Button2";
// import MainInput from "../../Common/Input/Main.input";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BirthDateMask, CardNumberMask, ExpirationDateMask } from "../../Common/Input/Masks/Payment.mask";
import MainContainer from "../../Container/Main.container";
import { billingInfoAction } from "../../Redux/BillingInfo/BillingInfo.slice";
import { dataAction } from "../../Redux/Data/Data.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import { OrderingType } from "../Trip/util/Trip.util";
import {
  findBillingInfo,
  PaymentPropType,
  PaymentValidationSchema
} from "./util/Payment.util";

const PaymentPage: FC<PaymentPropType> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { userInfo } = useSelector((state: any) => state.user);
  const { add_billing_response, billing_infos } = useSelector(
    (state: any) => state.billingInfo
  );

  const handleSubmit = (values: any) => {
    const toSend = {
      ...values,
      expiry: values.expiry,
      birth: values.birth,
    };
    dispatch(billingInfoAction.addBillingInfo(toSend)).then((res: any) => {
      if (res.payload) {
        if (res.payload?.data?.errorCode) {
          if (res.payload?.data?.errorCode === 351) {
            const billingInfo = billing_infos?.data?.data?.billing_info ?? [];
            dispatch(
              dataAction.setPaymentData(
                findBillingInfo(billingInfo, values.card_number)
              )
            );
            if (userInfo?.data?.data?.ordering?.id) {
              const ordering = userInfo?.data?.data?.ordering;
              dispatch(
                dataAction.setOrderingData({
                  startTime: ordering?.start_time,
                  ordering: ordering?.id,
                  scooterId: ordering?.scooter_id,
                  type: OrderingType?.transfer,
                })
              );
              navigate("/end-trip", { replace: true });
            } else {
              navigate("/trip", { replace: true });
            }
          } else {
            dispatch(
              snackActions.show({
                message: res.payload?.message ?? "Error occured",
                type: "error",
              })
            );
          }
        } else {
          dispatch(
            snackActions.show({
              message: "Billing information added",
              type: "success",
            })
          );
          if (userInfo?.data?.data?.ordering?.id) {
            navigate("/end-trip", { replace: true });
          } else {
            navigate("/trip", { replace: true });
          }
        }
      } else {
        dispatch(
          snackActions.show({
            message: res.payload?.message ?? "Operation failed",
            type: "error",
          })
        );
      }
    });
  };

  return (
    <MainContainer>
      <Formik
        initialValues={{
          card_number: "",
          expiry: null,
          birth: null,
          pwd_2digit: "",
        }}
        validationSchema={PaymentValidationSchema}
        onSubmit={(values: any) => handleSubmit(values)}
        className="w-full"
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col justify-center items-center"
          >
            <img src="/assets/images/credit-card.png" alt="Electric bike" />
            <div className="pt-4 w-full">
              <p className="text-[20px] font-bold pt-2">{t("payment.title")}</p>
            </div>
            <Grid container spacing={2} className="pt-4 pb-32">
              <Grid item xs={12}>
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold mb-2 text-black">
                    {t("payment.card_number")}
                  </label>
                  <StyledTextField
                    name="card_number"
                    size="small"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    value={values.card_number}
                    onChange={handleChange}
                    error={touched.card_number && Boolean(errors.card_number)}
                    helperText={touched.card_number && errors.card_number}
                    InputProps={{
                      autoComplete:'off',
                      inputComponent: CardNumberMask,
                      sx: {
                        fontSize: 14,
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold mb-2 text-black">
                    {t("payment.expiration_date")}
                  </label>
                  <StyledTextField
                    name="expiry"
                    size="small"
                    placeholder="YYYY-MM"
                    value={values.expiry}
                    onChange={handleChange}
                    error={touched.expiry && Boolean(errors.expiry)}
                    helperText={touched.expiry && errors.expiry}
                    InputProps={{
                      autoComplete:'off',
                      inputComponent: ExpirationDateMask,
                      sx: {
                        fontSize: 14,
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold mb-2 text-black">
                    {t("payment.birth_date")}
                  </label>
                  <StyledTextField
                    name="birth"
                    size="small"
                    placeholder="YYMMDD"
                    value={values.birth}
                    onChange={handleChange}
                    error={touched.birth && Boolean(errors.birth)}
                    helperText={touched.birth && errors.birth}
                    InputProps={{
                      autoComplete:'off',
                      inputComponent: BirthDateMask,
                      sx: {
                        fontSize: 14,
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold mb-2 text-black">
                    {t("payment.password")}
                  </label>
                  <StyledTextField
                    name="pwd_2digit"
                    size="small"
                    type={show ? "text" : "password"}
                    value={values.pwd_2digit}
                    onChange={handleChange}
                    placeholder="Your password (first 2 digits)"
                    error={touched.pwd_2digit && Boolean(errors.pwd_2digit)}
                    helperText={touched.pwd_2digit && errors.pwd_2digit}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingLeft: 0,
                        backgroundColor: "#F2F2F2",
                      },
                    }}
                    InputProps={{
                      autoComplete:'off',
                      sx: {
                        fontSize: 14,
                      },
                      endAdornment: (
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor: "#F2F2F2",
                            padding: 0.2,
                            marginLeft: 0,
                          }}
                        >
                          {!show ? (
                            <VisibilityOffIcon
                              fontSize="small"
                              onClick={() => setShow(true)}
                            />
                          ) : (
                            <RemoveRedEyeIcon
                              fontSize="small"
                              onClick={() => setShow(false)}
                            />
                          )}
                        </Box>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="absolute bottom-4 w-[350px] px-8">
              <Button2
                label={t("payment.register")}
                disabled={Object.keys(errors).length !== 0}
                type="submit"
                loading={add_billing_response?.loading}
                color="#FA4E3E"
              />
            </div>
          </form>
        )}
      </Formik>
    </MainContainer>
  );
};

export const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "lightgreen",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "blue",
  },
  "& .MuiInputBase-input": {
    fontWeight: "5px",
    backgroundColor: "#EFEFEF",
    padding: 10,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: "3px",
      borderColor: "#D8D8D8",
      borderRadius: 7,
    },
    "&:hover fieldset": {
      borderColor: "#D8D8D8",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "3px",
      borderColor: "#D8D8D8",
      borderRadius: 7,
    },
  },
});

export default PaymentPage;
