import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { billingInfoReducer } from "./BillingInfo/BillingInfo.slice";
import { dataReducer } from "./Data/Data.slice";
import { locationReducer } from "./Location/Location.slice";
import { orderingReducer } from "./Ordering/Ordering.slice";
import { parkingReducer } from "./Parking/Parking.slice";
import { ScooterReducer } from "./Scooter/Scooter.slice";
import { ServiceAreaReducer } from "./ServiceArea/ServiceArea.slice";
import { rideReducer } from "./Trip/Trip.slice";
import { userReducer } from "./User/User.slice";
import { snackReducers } from "./Util/Snack.slice";

const middlewares: any = [];

// if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState: any, action: any, logEntry: any) => !logEntry.error });

  middlewares.push(logger);
// }

const store = configureStore({
  reducer: {
    user: userReducer,
    snack: snackReducers,
    location: locationReducer,
    billingInfo: billingInfoReducer,
    serviceArea: ServiceAreaReducer,
    data: dataReducer,
    scooter: ScooterReducer,
    trip: rideReducer,
    parking: parkingReducer,
    ordering: orderingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
