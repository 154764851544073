// credential configuration : AWS access key, secret key, region and naver maps creds. With api keys
const credentials = {
    accessKey: "AKIA5KBD37R7IBZINCV6",
    secretKey: "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",
    region: "ap-northeast-2",
    clientId: "29fkmgh9aksufk5pl0gh2qhife",
    naverClientID: "33kxabt7tg",
    naverSecretKey: "3IGxvaBEsBik0epvvcNbRuBMY8sJPHqlEoD6undU",
    keyId: "mbR3mNPqQGIPJ04A3hIi5X7AB6rTpvl2wUsGhqwh",
    userKeyId: "f4JbOU9OE55j5Cdrgaapr1Qecw63Q4197KAZ1393"
};

export default credentials;