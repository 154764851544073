import { FC } from "react";
import { TitlePropType } from "./Typography.util";
import clx from "classnames";

const Title:FC<TitlePropType> = ({label, variant}) => {
    return <p className={clx(["text-4xl font-bold pt-2", {
        "text-4xl": variant === "large" || !variant,
        "text-2xl": variant === "medium"
    }])}>{label}</p>
};

export default Title;