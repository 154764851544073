import { FC } from "react";
import { DetailPropType } from "./Detail.util";

const DetailComponent: FC<DetailPropType> = ({label, value}) => {
  return (
    <div className="flex flex-col">
      <p className="font-bold text-sm">{label}</p>
      <div className="border-[3px] border-[#D8D8D8] rounded-lg bg-[#EFEFEF] p-2 w-full">
        <p className="color-black opacity-50 text-[14px]">{value}</p>
      </div>
    </div>
  );
};

DetailComponent.defaultProps = {
    label: "",
    value: ""
}

export default DetailComponent;
