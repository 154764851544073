import { createAction, createSlice } from "@reduxjs/toolkit";

const name = "snack";
const initialState = {
  show: false,
  message: "",
  type: "warning",
  sub: "",
  isCustom: false,
  color: ""
};
const extraActions: any = createExtraActions();
const extraReducers = createExtraReducers();

const snackSlice = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const snackActions = { ...snackSlice.actions, ...extraActions };
export const snackReducers = snackSlice.reducer;

function createExtraActions() {
  return {
    show: show(),
    hide: hide(),
  };

  function show() {
    return createAction(`${name}/show`);
  }

  function hide() {
    return createAction(`${name}/hide`);
  }
}

function createExtraReducers() {
  return {
    ...show(),
    ...hide(),
  };

  function show() {
    return {
      [extraActions.show]: (state: any, action: any) => {
        state.show = true;
        state.type = action.payload?.type;
        state.message = action.payload?.message;
        state.sub = action.payload?.sub;
        state.isCustom = action.payload?.isCustom
        state.color = action.payload?.color
      },
    };
  }

  function hide() {
    return {
      [extraActions.hide]: (state: any) => {
        state.show = false;
        state.message = "";
        state.type = "warning";
        state.sub = "";
        state.isCustom = false;
        state.color = "";
      },
    };
  }
}
