import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BillingInfoHeader } from "../User/User.type";
import axe from "axios";
import axios from "../Util/axios.util";
// import axios from "axios";

const name: string = "billing_info";

const createExtraActions: any = () => {
  return {
    addBillingInfo: addBillingInfo(),
    getBillingInfo: getBillingInfo(),
    reset: reset(),
  };

  function getBillingInfo() {
    return createAsyncThunk(`${name}/getBillingInfo`, async () =>
      axios.axiosInstance.get(name)
    );
  }

  function addBillingInfo() {
    return createAsyncThunk(`${name}/add`, async (data: any) =>
      axe.post("https://dev.e-flying.co/scooter/auth/billing_info", {}, BillingInfoHeader(data))
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...addBillingInfo(),
    ...getBillingInfo(),
    ...reset(),
  };

  function addBillingInfo() {
    const { pending, fulfilled, rejected }: any = extraActions.addBillingInfo;
    return {
      [pending]: (state: any) => {
        state.add_billing_response = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.add_billing_response = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.add_billing_response = { error: action.error };
      },
    };
  }

  function getBillingInfo() {
    const { pending, fulfilled, rejected }: any = extraActions.getBillingInfo;
    return {
      [pending]: (state: any) => {
        state.billing_infos = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.billing_infos = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.billing_infos = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.add_billing_response = {};
        state.billing_infos = {};
      },
    };
  }
};

const initialState: any = {
  add_billing_response: {},
  billing_infos: {},
};

const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const billingInfoSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const billingInfoAction = {
  ...billingInfoSlice.actions,
  ...extraActions,
};
export const billingInfoReducer = billingInfoSlice.reducer;
