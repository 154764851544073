import { Route, Routes } from "react-router-dom";
import BlockedPage from "../Pages/Blocked/Blocked.page";
import ConfirmationPage from "../Pages/Confirmation/ConfirmationPage";
import HomePage from "../Pages/Home/home";
import InsurancePolicy from "../Pages/InsurancePolicy/InsurancePolicy.page";
import LanguagePage from "../Pages/Language/Language";
import PaymentPage from "../Pages/Payment/Payment.page";
import PaymentListPage from "../Pages/Payment/PaymentList.page";
import CameraFeed from "../Pages/Picture/CameraFeed";
import PicturePage from "../Pages/Picture/PicturePage";
import ScooterPage from "../Pages/Scooter/Scooter";
import SuccessfulPage from "../Pages/Successful/Successful";
import SummaryPage from "../Pages/Summary/SummaryPage";
import UnPaidTrip from "../Pages/Summary/UnPaid.trip";
import EndTripPage from "../Pages/Trip/EndTrip.page";
import TripPage from "../Pages/Trip/Trip.page";
import VerifyPage from "../Pages/Verify/Verify.page";
import WelcomePage from "../Pages/Welcome/Welcome";

const Routing = () => {
  return (
    <Routes>
      <Route path="/s/:scooterId" element={<HomePage />} />
      <Route path ="/scooter/:scooterId" element={<ScooterPage />} />
      <Route path="/language" element={<LanguagePage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/verify" element={<VerifyPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/saved-payment" element={<PaymentListPage />} />
      <Route path="/trip" element={<TripPage />} />
      <Route path="/end-trip" element={<EndTripPage />} />
      <Route path="/picture" element={<PicturePage />} />
      <Route path="/camera-feed" element={<CameraFeed />} />
      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/summary" element={<SummaryPage />} />
      <Route path="/unpaid" element={<UnPaidTrip />} />
      <Route path="/success" element={<SuccessfulPage />} />
      <Route path="/blocked" element={<BlockedPage />} />
      <Route path="/insurance-policy" element={<InsurancePolicy />} />
    </Routes>
  );
};

export default Routing;
