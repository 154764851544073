export type VerifyPropType = {
}

export const setUserData = (authData: {AccessToken: string, IdToken: string}) => {
    localStorage.setItem("IdToken", authData?.IdToken);
    localStorage.setItem("AccessToken", authData?.AccessToken)
}

export const getUserData = () => {
    const idToken = localStorage.getItem("IdToken");
    const accessToken = localStorage.getItem("AccessToken");
    return {idToken, accessToken}
}

export const handleLogout = () => {
    // localStorage.removeItem("IdToken");
    // localStorage.removeItem("AccessToken")
}

export const formatPhoneNumber = (phone: string) => {
    const first = phone?.slice(0,3);
    const second = phone?.slice(3,5);
    const third = phone?.slice(5,9);
    const four = phone?.slice(9, 13)
    return `${first}-${second}-${third}-${four}`;
}