
const InsurancePolicy = () => {

  return (
    <div>
      <img src="/assets/images/img_insurance_guide_1440x7504.jpg" />
    </div>
  );
};

export default InsurancePolicy;
