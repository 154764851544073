import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import credentials from "../../Config/credentials.config";
import { cognitoidentityserviceprovider } from "../../Pages/Welcome/util/Welcome.util";
import axios from "axios";
import { UserInfoHeader } from "./User.type";

const createExtraActions: any = () => {
  return {
    initiate: initiate(),
    signup: signup(),
    verify: verify(),
    userInfo: userInfo(),
    reset: reset(),
  };

  function initiate() {
    return createAsyncThunk(`${name}/initiate`, async (data) => {
      const params: any = {
        AuthFlow: "CUSTOM_AUTH",
        ClientId: credentials.clientId,
        AuthParameters: {
          USERNAME: data,
        },
      };
      return cognitoidentityserviceprovider.initiateAuth(params).promise();
    });
  }

  function signup() {
    return createAsyncThunk(`${name}/signup`, async (data) => {
      const params: any = {
        ClientId: credentials.clientId,
        Password: data,
        Username: data,
      };
      return cognitoidentityserviceprovider.signUp(params).promise();
    });
  }

  function verify() {
    return createAsyncThunk(`${name}/verify`, async (data: any) => {
      const params: any = {
        ClientId: credentials.clientId,
        ChallengeName: "CUSTOM_CHALLENGE",
        Session: data?.session,
        ChallengeResponses: {
          ANSWER: data?.code,
          USERNAME: data?.phone,
        },
      };
      return cognitoidentityserviceprovider
        .respondToAuthChallenge(params)
        .promise();
    });
  }

  function userInfo() {
    return createAsyncThunk(`${name}/userInfo`, async () =>
      axios.get(`https://dev.e-flying.co/scooter/auth/user_info`, UserInfoHeader())
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...initiate(),
    ...signup(),
    ...userInfo(),
    ...verify(),
    ...reset(),
  };

  function initiate() {
    const { pending, fulfilled, rejected }: any = extraActions.initiate;
    return {
      [pending]: (state: any) => {
        state.initiate = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.initiate = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.initiate = { error: action.error };
      },
    };
  }

  function signup() {
    const { pending, fulfilled, rejected }: any = extraActions.signup;
    return {
      [pending]: (state: any) => {
        state.signup = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.signup = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.signup = { error: action.error };
      },
    };
  }

  function verify() {
    const { pending, fulfilled, rejected }: any = extraActions.verify;
    return {
      [pending]: (state: any) => {
        state.verify = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.verify = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.verify = { error: action.error };
      },
    };
  }

  function userInfo() {
    const {pending, fulfilled, rejected}: any = extraActions.userInfo;
    return {
      [pending]: (state: any) => {
        state.userInfo = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.userInfo = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.userInfo = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.initiate = {};
        state.signup = {};
        state.verify = {};
        state.userInfo = {}
      },
    };
  }
};

const initialState: any = {
  initiate: {},
};
const name: any = "user";
const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const userSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const userAction = { ...userSlice.actions, ...extraActions };
export const userReducer = userSlice.reducer;
