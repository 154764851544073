import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pureAxios from "axios";
import { UserInfoHeader } from "../User/User.type";
import axios from "../Util/axios.util";

const name: string = "scooter_list";

export type Scooter = {
  area_name: string;
  available: { lat: string; lon: string }[];
  description: string;
  parking_spot: any[];
  type: number;
};

const createExtraActions: any = () => {
  return {
    // getAllScooters: getAllScooters(),
    getScooter: getScooter(),
    getScooters: getScooters(),
    reset: reset(),
  };

  // function getAllScooters() {
  //   return createAsyncThunk(`${name}/getAll`, async () =>
  //     axios.axiosInstance.get(name)
  //   );
  // }

  function getScooter() {
    return createAsyncThunk(`${name}/getScooter`, async (id) =>
      axios.axiosInstance.get(`scooter_info?scooter_id=${id}`, UserInfoHeader())
    );
  }

  function getScooters() {
    return createAsyncThunk(`${name}/getScooters`, async (data: any) =>
      axios.noAuthAxiosInstance.get(
        `scooter_list?lon=${data.longitude}&lat=${data.latitude}&distance=${data.distance}`
      )
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    // ...getAllScooters(),
    ...getScooter(),
    ...getScooters(),
    ...reset(),
  };

  // function getAllScooters() {
  //   const { pending, fulfilled, rejected }: any = extraActions.getAllScooters;
  //   return {
  //     [pending]: (state: any) => {
  //       state.scooters = { loading: true };
  //     },
  //     [fulfilled]: (state: any, action: any) => {
  //       state.scooters = action.payload;
  //     },
  //     [rejected]: (state: any, action: any) => {
  //       state.scooters = { error: action.error };
  //     },
  //   };
  // }

  function getScooter() {
    const { pending, fulfilled, rejected }: any = extraActions.getScooter;
    return {
      [pending]: (state: any) => {
        state.scooter = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.scooter = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.scooter = { error: action.error };
      },
    };
  }

  function getScooters() {
    const { pending, fulfilled, rejected }: any =
      extraActions.getScooters;
    return {
      [pending]: (state: any) => {
        state.scooters = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.scooters = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.scooters = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.scooters = {};
        state.scooter = {};
      },
    };
  }
};

const initialState: any = {
  scooters: {},
  scooter: {},
};

const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const scooterSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const ScooterAction = { ...scooterSlice.actions, ...extraActions };
export const ScooterReducer = scooterSlice.reducer;
