import MainContainer from "../../Container/Main.container";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const ConfirmationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rideEnd } = useSelector((state: any) => state.trip);


  useEffect(() => {
    if (rideEnd?.data?.data?.amount) {
      navigate("/summary", {replace: true})
    }
  }, [rideEnd]);

  return (
    <MainContainer>
      <TaskAltIcon className="text-main" sx={{ fontSize: "80px", color:"#fa4f3f" }} />
      <p className="text-bold text-xl text-center pt-16 pb-28">
        {t("confirmation.calculating")}
      </p>
      <CircularProgress sx={{color: "#fa4f3f"}} />
    </MainContainer>
  );
};

export default ConfirmationPage;
