import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userAction } from "../User/User.slice";
import axios from "../Util/axios.util";

const name: string = "service_area";

export type ServiceArea = {
  area_name: string;
  available: { lat: string; lon: string }[];
  description: string;
  parking_spot: any[];
  type: number;
  amount?: number;
};

const createExtraActions: any = () => {
  return {
    getAllServiceArea: getAllServiceArea(),
    getAllNoAuthServiceArea: getAllNoAuthServiceArea(),
    reset: reset(),
  };

  function getAllServiceArea() {
    return createAsyncThunk(`${name}/getAll`, async () =>
      axios.userAxiosInstance.get(name)
    );
  }

  function getAllNoAuthServiceArea() {
    return createAsyncThunk(`${name}/noAuthServiceArea`, async () =>
      axios.noAuthAxiosInstance.get(name)
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...getAllServiceArea(),
    ...getAllNoAuthServiceArea(),
    ...reset(),
  };

  function getAllServiceArea() {
    const { pending, fulfilled, rejected }: any =
      extraActions.getAllServiceArea;
    return {
      [pending]: (state: any) => {
        state.serviceAreas = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.serviceAreas = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.serviceAreas = { error: action.error };
      },
    };
  }

  function getAllNoAuthServiceArea() {
    const { pending, fulfilled, rejected }: any =
      extraActions.getAllNoAuthServiceArea;
    return {
      [pending]: (state: any) => {
        state.noAuthServiceArea = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.noAuthServiceArea = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.noAuthServiceArea = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.serviceAreas = {};
        state.noAuthServiceArea = {};
      },
    };
  }
};

const initialState: any = {
  serviceAreas: {},
  noAuthServiceArea: {}
};

const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const serviceAreaSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const ServiceAreaAction = {
  ...serviceAreaSlice.actions,
  ...extraActions,
};
export const ServiceAreaReducer = serviceAreaSlice.reducer;
