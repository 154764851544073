// 서울특별시
// api and navermaps link
const url: IUrl = {
  geocode: "https://naveropenapi.apigw.ntruss.com/map-geocode/v2/geocode?query=dfg&limit=5",
  baseUrl: "https://dev.e-flying.co/scooter/auth/",
  userBaseUrl: "https://dev.e-flying.co/scooter/info/user/",
  noAuth: "https://ext.e-flying.co/external/scooter/"
};

type IUrl = {
  geocode: string;
  baseUrl: string;
  userBaseUrl: string;
  noAuth: string
};

export default url;
