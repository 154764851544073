import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dataAction } from "../../Redux/Data/Data.slice";
import { userAction } from "../../Redux/User/User.slice";
import { OrderingType } from "../Trip/util/Trip.util";

const HomePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const inTrip = localStorage.getItem("ordering");
  const token = localStorage.getItem("AccessToken");
  const { userInfo } = useSelector((state: any) => state.user);

    if (inTrip === "true" && token !== null) {
      dispatch(userAction.userInfo())
        .then(({ payload }: any) => {
          const responseData = payload?.data?.data ?? {};
          if (responseData?.blocked?.reason) {
            navigate("/blocked", { replace: true });
          } else if (responseData?.ordering?.id) {
            const ordering = userInfo?.data?.data?.ordering;
            dispatch(
              dataAction.setOrderingData({
                startTime: ordering?.start_time,
                ordering: ordering?.id,
                scooterId: ordering?.scooter_id,
                type: OrderingType?.transfer,
              })
            );
            navigate("/end-trip", { replace: true });
          } else if (responseData?.ordering?.no_paid?.length > 0) {
            navigate("/unpaid", { replace: true });
          } else {
            if (responseData?.billing_info?.length > 0) {
              navigate("/saved-payment", { replace: true });
            } else {
              navigate(`/scooter/${params.scooterId}`);
            }
          }
        })
        .catch((err: any) => {
          navigate(`/scooter/${params.scooterId}`);
        });
    } else {
      navigate(`/scooter/${params.scooterId}`, {replace: true});
    }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </> 
  );
};

export default HomePage;
