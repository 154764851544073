import { BlockedPagePropType } from "./Blocked.util";
import { FC } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import MainContainer from "../../Container/Main.container";
import DetailComponent from "../../Common/Detail/Detail";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const BlockedPage: FC<BlockedPagePropType> = ({}) => {
  const {t} = useTranslation()
  const { userInfo } = useSelector((state: any) => state.user);
  return (
    <MainContainer>
      <WarningIcon
        sx={{
          color: "#c14124",
          fontSize: { lg: 100, md: 100, sm: 80, xs: 80 },
        }}
      />
      <p className="font-bold text-xl mt-4">{t("blocking.title")}</p>
      <Grid container sx={{ marginTop: 3 }} spacing={2}>
        <Grid item xs={6}>
          <DetailComponent
            label={t("blocking.blocked_date")}
            value={moment(userInfo?.data?.blocked?.blocked_date).format(
              "YYYY-MM-DD"
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailComponent
            label={t("blocking.release_date")}
            value={moment(userInfo?.data?.blocked?.release_date).format(
              "YYYY-MM-DD"
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DetailComponent label={t("blocking.reason")} value={userInfo?.data?.blocked?.reason} />
        </Grid>
        {userInfo?.data?.blocked?.description && (
          <Grid item xs={12}>
            <DetailComponent
              label={t("blocking.description")}
              value={userInfo?.data?.blocked?.description}
            />
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
};

export default BlockedPage;
