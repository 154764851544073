import { FC } from "react";

const RenderMap: FC<{
  isGeolocationEnabled: boolean;
  isGeolocationAvailable: boolean;
  children: any;
  coords?: any;
}> = ({ isGeolocationAvailable, isGeolocationEnabled, children, coords }) => {
  if (!isGeolocationAvailable)
    return (
      <div className="flex items-center justify-center h-screen bg-[#000000]">
        <p>Your device does not support geolocation</p>
      </div>
    );
  if (!isGeolocationEnabled)
    return (
      <div className="flex items-center justify-center h-screen bg-[#000000]">
        <p>Geolocation is not enabled</p>
      </div>
    );
  return coords?.latitude ? children : <p />;
};

export default RenderMap;
