import { useEffect, useState } from "react";
import { useTakePicture } from "./Component/useTakePicture";
import MainContainer from "../../Container/Main.container";
import Button from "../../Common/Button/Button2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { parkingAction } from "../../Redux/Parking/Parking.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { rideAction } from "../../Redux/Trip/Trip.slice";
const FullScreenMobileView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { captureImage, imageData } = useTakePicture();
  const [imageDatas, handleImageDatas] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const { parking } = useSelector((state: any) => state.parking);
  const { scooter: scooterData, ordering } = useSelector(
    (state: any) => state.data
  );
  const { userInfo } = useSelector((state: any) => state.user);
  const { rideEnd } = useSelector((state: any) => state.trip);

  useEffect(() => {
    handleImageDatas(imageData);
  }, [imageData]);

  const handleParkingUpload = () => {
    dispatch(
      parkingAction.addParking({
        scooter_id: scooterData?.serial_number,
        phone_number: userInfo?.data?.data?.user?.userName,
        image: imageDatas?.replace(/^data:image\/(png|jpg);base64,/, ""),
        description,
      })
    )
      .then(({ payload }: any) => {
        dispatch(
          rideAction.rideEnd({ serialNumber: localStorage.getItem("scooter") })
        )
          .then((res: any) => {
            if (res?.payload?.data?.errorCode !== 0) {
              navigate("/welcome", { replace: true });
              dispatch(
                snackActions.show({
                  message: payload?.data?.message ?? "Ride end failed",
                  type: "error",
                })
              );
            } else {
              localStorage.setItem("is_done", "false");
              localStorage.setItem("ordering", "false");
              navigate("/confirmation", { replace: true });
            }
          })
          .catch((error: any) => {
            navigate("/welcome", { replace: true });
            dispatch(
              snackActions.show({
                message: payload?.data?.message ?? "Ride end failed",
                type: "error",
              })
            );
          });
      })
      .catch((err: any) => {
        dispatch(
          snackActions.show({
            message: err?.message ?? "File not uploaded",
            type: "error",
          })
        );
      });
  };

  return (
    <MainContainer>
      <div
        className={`${
          imageDatas && "hidden"
        } border-2 border-[#D8D8D8] rounded-md`}
      >
        <video width="100%" height="40%" />
        <canvas style={{ opacity: 0 }} height="100" />
      </div>

      <div
        className={`${
          !imageDatas && "hidden"
        } border-2 border-[#D8D8D8] rounded-md`}
      >
        <img src={imageData} width={"100%"} alt="NoImage" />
      </div>

      <div className="text-center pt-2">
        <p className="font-semibold">
          {imageDatas ? t("camera.confirmation") : t("camera.ask")}
        </p>
        {imageDatas && (
          <div
            onClick={() => handleImageDatas("")}
            className="my-[15px] text-[19px]"
          >
            <span className="text-main text-bold underline font-bold cursor-pointer">
              {t("common.click")}
            </span>{" "}
            {t("camera.another_photo")}
          </div>
        )}
      </div>

      {imageDatas && (
        <StyledTextField
          name="Description"
          size="small"
          multiline
          rows={4}
          placeholder={t("camera.description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full text-[16px]"
          style={{
            backgroundColor: "#EFEFEF",
            marginTop: 13,
          }}
          InputProps={{ inputProps: { min: 0, max: 10 } }}
        />
      )}

      <div className="absolute bottom-4 w-[350px] px-8">
        {imageDatas ? (
          <Button
            label={t("camera.send_picture")}
            handleClick={handleParkingUpload}
            loading={parking?.loading || rideEnd?.loading} 
            color="#FA4E3E"
          />
        ) : (
          <Button
            label={t("camera.take_picture")}
            loading={parking?.loading || rideEnd?.loading} 
            handleClick={captureImage}
            color="#FA4E3E"
          />
        )}
      </div>
    </MainContainer>
  );
};

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "lightgreen",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "blue",
  },
  "& .MuiInputBase-input": {
    fontSize: "10px",
    fontWeight: "5px",
    backgroundColor: "#EFEFEF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      padding: 0,
      margin: 0,
      borderWidth: "3px",
      borderColor: "#D8D8D8",
      borderRadius: 7,
    },
    "&:hover fieldset": {
      borderColor: "#D8D8D8",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "3px",
      borderColor: "#D8D8D8",
      borderRadius: 7,
    },
  },
});

export default FullScreenMobileView;
