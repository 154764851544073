import { FC } from "react";
import MainContainer from "../../Container/Main.container";
import { LanguagePropType } from "./util/Language.util";
import LanguageSelector from "./components/LanguageSelector";
import Button from "../../Common/Button/Button2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";



const LanguagePage: FC<LanguagePropType> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <MainContainer>
      <div className="absolute top-2 right-4">
        <HighlightOffIcon sx={{ color: "#999999" }} onClick={() => navigate("/welcome")} />
      </div>
      <img
        src="/assets/images/flowerroad_logo@3x.png"
        width={90}
        alt="Electric bike"
      />
      <div className="w-full mt-16 mb-16">
        <p className="text-lg mb-2">{t("language.select")}</p>
        <LanguageSelector />
      </div>
      <div className="absolute bottom-4 w-[350px] px-8">
        <Button label={t("common.button.continue")} color="#000000" handleClick={() => navigate("/welcome")}/>
      </div>
    </MainContainer>
  );
};

export default LanguagePage;
