import CircleIcon from "@mui/icons-material/Circle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox } from "@mui/material";
import List from "@mui/material/List";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/Button/Button2";
import { dataAction } from "../../Redux/Data/Data.slice";
import { OrderingType } from "../Trip/util/Trip.util";
const PaymentListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.user);
  const billingInfos = userInfo?.data?.data?.billing_info ?? [];
  const [selectedCard, setSelectedCard] = useState<any>();

  const [checked, setChecked] = useState<boolean>(false);

  const handleSavedPayment = () => {
    dispatch(dataAction.setPaymentData(selectedCard));
    if (userInfo?.data?.data?.ordering?.id) {
      const ordering = userInfo?.data?.data?.ordering;
      dispatch(
        dataAction.setOrderingData({
          startTime: ordering?.start_time,
          ordering: ordering?.id,
          scooterId: ordering?.scooter_id,
          type: OrderingType?.transfer,
        })
      );
      navigate("/end-trip", { replace: true });
    } else {
      navigate("/trip", { replace: true });
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-[350px]">
      <div className="flex flex-col justify-center items-center w-full px-8">
        <img
          src="/assets/images/credit-card.png"
          className="w-20 mt-8"
          alt="Credit card"
        />
        <p className="text-[20px] text-black font-medium pt-4 mb-[8px] w-full">
          {t("payment_list.title")}
        </p>
        <p className="text-[14px] mb-12">{t("payment_list.description")}</p>
        <List className="w-full space-y-4">
          {billingInfos.map((item: any) => (
            <div className="w-full border-[3px] border-[#D8D8D8] rounded-md flex flex-row bg-[#F2F2F2]">
              <div className="flex justify-center align-center border-r-[3px] border-r-[#D8D8D8] w-2/12">
                <Checkbox
                  checked={item?.card_id === selectedCard?.card_id}
                  onChange={() => setSelectedCard(item)}
                  checkedIcon={<CircleIcon sx={{ color: "#000000" }} />}
                  icon={<RadioButtonUncheckedIcon sx={{ color: "#D8D8D8" }} />}
                />
              </div>
              <div className="flex flex-col justify-center text-[14px] w-10/12">
                <div className="flex flex-row justify-start items-center border-b-[3px] border-b-[#D8D8D8] py-[5px] pl-2">
                  <p className="w-[50px]">Card #</p>
                  <FiberManualRecordIcon
                    sx={{ fontSize: 6, color: "#FA4E3E", marginRight: 1 }}
                  />
                  <p className="flex justify-start">
                    **** **** **** {item?.card_id}
                  </p>
                </div>
                <div className="flex flex-row  justify-start items-center py-[5px] pl-2">
                  <p className="w-[50px]">Name</p>
                  <FiberManualRecordIcon
                    sx={{ fontSize: 6, color: "#FA4E3E", marginRight: 1 }}
                  />
                  <p className="flex justify-start">{item?.card_name}</p>
                </div>
              </div>
            </div>
          ))}
        </List>

        <div className="absolute bottom-4 w-[350px] px-8">
          <div className="w-full mb-4">
            <p>
              Register a new card? Click{" "}
              <span
                className="text-[#175BBE]"
                style={{ textDecoration: "underline" }}
                onClick={() => navigate("/payment")}
              >
                here.
              </span>
            </p>
          </div>
          <CustomButton
            label={t("common.button.continue")}
            disabled={!selectedCard}
            handleClick={() => handleSavedPayment()}
            color="#FA4E3E"
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default PaymentListPage;
