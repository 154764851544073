import { useState } from "react";
import clx from "classnames";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTranslation } from "react-i18next";
const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("Korea");

  const handleOpen = () => setOpen(true);

  return (
    <>
      
      <div className="inline-flex flex-col text-gray-600 justify-center relative w-full">
        <div className="relative">
          <div
            className={clx(
              "border-[3px] border-[#D8D8D8] bg-[#F2F2F2] py-2 px-4 flex justify-between",
              { "rounded-md": !open, "rounded-tl-md rounded-tr-md": open }
            )}
            onClick={handleOpen}
          >
            <div className="text-[#242424] font-bold">{i18n.language === "en" ? "English" : "한국어"}</div>
            {!open ? (
              <ArrowDropDownIcon sx={{ color: "#242424" }} />
            ) : (
              <ArrowDropUpIcon sx={{ color: "#242424" }} />
            )}
          </div>
        </div>
        {open && (
          <div
            className="absolute top-[46px] bg-white border-x-[3px] border-x-[#D8D8D8] border-b-[3px] border-b-[#D8D8D8] w-full rounded-bl-md rounded-br-md"
            onClick={() => {
              i18n.changeLanguage(language === "English" ? "kr" : "en");
              setLanguage((lan) => (lan === "English" ? "한국어" : "English"));
              setOpen(false);
            }}
          >
            <div className="text-[#242424] font-bold bg-[#F2F2F2] py-2 px-4">
              {i18n.language === "en" ? "한국어" : "English"}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageSelector;
