import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Common/Button/Button2";
import MainContainer from "../../Container/Main.container";
import { convertHMS, formatToCurrency } from "./util/Summary.util";
const SummaryPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { confirmPayment } = useSelector((state: any) => state.ordering);
  const { rideEnd } = useSelector((state: any) => state.trip);
  const { trip } = useSelector((state: any) => state.data);
  const handleConfirmPayment = () => {
    navigate("/success");
  };

  return (
    <MainContainer>
      {rideEnd?.error && (
        <Alert severity="error">{rideEnd?.data?.message}</Alert>
      )}
      {rideEnd?.data?.data?.amount && (
        <>
          <div className="flex flex-col justify-center items-center">
            <img
              src="/assets/images/thank-you@3x.png"
              width={80}
              alt="Electric bike"
            />
            <p className="text-2xl font-bold pt-4">{t("summary.trip_ended")}</p>
          </div>
          <div className="w-full pt-8 pb-[60px]">
            <p className="text-[18px] font-bold mb-[8px]">
              {t("summary.summary")}
            </p>
            <div className="text-[14px] text-black border-2 border-[#D8D8D8] rounded-lg bg-[#F6F6F6] w-full">
              <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.used_time")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    {`${convertHMS(rideEnd?.data?.data?.used_time)}`}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.voucher_amount")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    ₩ {formatToCurrency(rideEnd?.data?.data?.voucher_amount)}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.amount")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    ₩ {formatToCurrency(rideEnd?.data?.data?.amount)}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between  border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.total_fare")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    ₩ {formatToCurrency(rideEnd?.data?.data?.total_amount)}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.insurance_fee")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    ₩ {formatToCurrency(rideEnd?.data?.data?.insurance_fee)}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                <div className="">{t("summary.insurance_user_fee")}</div>
                <div className="flex flex-row items-center">
                  <FiberManualRecordIcon
                    className="text-[#FA4E3E]"
                    style={{ fontSize: "8px" }}
                  />
                  <div className="flex justify-start font-bold w-[80px] ml-12">
                    ₩ {formatToCurrency(rideEnd?.data?.data?.insurance_user_fee)}
                  </div>
                </div>
              </div>
              {rideEnd?.data?.data?.insurance_discount_fee !== 0 && (
                <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                  <div className="">{t("summary.insurance_discount_fee")}</div>
                  <div className="flex flex-row items-center">
                    <FiberManualRecordIcon
                      className="text-[#FA4E3E]"
                      style={{ fontSize: "8px" }}
                    />
                    <div className="flex justify-start font-bold w-[80px] ml-12">
                      ₩ {formatToCurrency(rideEnd?.data?.data?.insurance_discount_fee)}
                    </div>
                  </div>
                </div>
              )}
              {rideEnd?.data?.data?.penalty?.amount && (
                <div className="flex flex-row items-center justify-between border-b-2 border-b-[#D8D8D8] px-[13px] py-[7px]">
                  <div className="">{t("summary.penality")}</div>
                  <div className="flex flex-row items-center">
                    <FiberManualRecordIcon
                      className="text-[#FA4E3E]"
                      style={{ fontSize: "8px" }}
                    />
                    <div className="flex justify-start font-bold w-[80px] ml-12">
                      ₩ {formatToCurrency(rideEnd?.data?.data?.penalty?.amount)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="absolute bottom-4 w-[350px] px-8">
            <Button
              label={t("common.button.close")}
              loading={confirmPayment?.loading}
              handleClick={handleConfirmPayment}
              color="#FA4E3E"
            />
          </div>
        </>
      )}
    </MainContainer>
  );
};

export default SummaryPage;
