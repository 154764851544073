import MainContainer from "../../Container/Main.container";
import DescriptionIcon from "@mui/icons-material/Description";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Button from "../../Common/Button/Button2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UnPaidTrip = () => {
  const {t} = useTranslation();
    const { userInfo } = useSelector((state: any) => state.user);
    return (
    <MainContainer>
      <DescriptionIcon sx={{ color: "#ffc10d", fontSize: 50 }} />
      <p className="font-bold text-xl mt-4">{t("unpaid.title")}</p>
      <div className="py-8 w-full text-center">
        <p className="text-xs font-thin">
          {t("unpaid.description")}
        </p>
      </div>
      <div className="w-full">
        <p className="text-md font-bold">{t("unpaid.summary")}</p>
        <div className="border-2 border-[#D8D8D8] rounded-lg bg-[#F6F6F6] w-full">
            <div className="flex flex-row items-center border-b-2 border-b-[#D8D8D8]">
                <div className="text-md text-black px-[13px] pr-[15px] pt-[7px] pb-[7px]">{t("unpaid.distance")}</div>
                <FiberManualRecordIcon className="text-[#277BC0]" style={{fontSize: "8px"}} />
                <div className="text-md text-black font-bold pl-4">7.5 km</div>
            </div>
            <div className="flex flex-row items-center border-b-2 border-b-[#D8D8D8]">
                <div className="text-md text-black px-[13px] pr-[15px] pt-[7px] pb-[7px]">{t("unpaid.start_time")}</div>
                <FiberManualRecordIcon className="text-[#277BC0]" style={{fontSize: "8px"}} />
                <div className="text-md text-black font-bold pl-4">9:00AM, 2021-01-11</div>
            </div>
            <div className="flex flex-row items-center">
                <div className="text-md text-black px-[13px] pr-[10px] pt-[7px] pb-[7px]">{t("unpaid.end_time")}</div>
                <FiberManualRecordIcon className="text-[#277BC0]" style={{fontSize: "8px"}} />
                <div className="text-md text-black font-bold pl-4">9:10AM, 2021-01-11</div>
            </div>
            <div className="flex flex-row items-center">
                <div className="text-md text-black px-[13px] pr-[10px] pt-[7px] pb-[7px]">{t("unpaid.total_fare")}</div>
                <FiberManualRecordIcon className="text-[#277BC0]" style={{fontSize: "8px"}} />
                <div className="text-md text-black font-bold pl-4">₩ 1,800.00</div>
            </div>
        </div>
      </div>
      <div className="text-center text-xs pt-16">
        <p>{t("unpaid.confirm")}</p>
        <div className="pt-2">
        <Button label={t("unpaid.confirm_payment")} loading={false} handleClick={() => {}} />
        </div>
      </div>
    </MainContainer>
  );
};

export default UnPaidTrip;
