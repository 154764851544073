import { Component } from 'react';
import { getScooter } from '../Pages/Language/util/Language.util';
import withRouter from './withRouter';

let loginRedirectUrl: any = null;

class Authorization extends Component<any, any> {
  constructor(props: any, context: any) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
    };
  }

  componentDidMount() {
    // if (!this.state.accessGranted) {
    this.redirectRoute();
    // }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    // if (!this.state.accessGranted) {
    this.redirectRoute();
    // }
  }

  redirectRoute() {
    const { location, navigate, params }: any = this.props;
    const { pathname } = location;
    const isUserAuthenticated = !!localStorage.getItem("scooter");
    const done = localStorage.getItem("is_done")
    const scooterId = getScooter();
    if ((!scooterId || scooterId === undefined) && done === "false") {
      // setTimeout(() => navigate(`/s/${localStorage.getItem("scooter")}`, {replace: true}), 0);
    } else {
      if (done) {
        if (done === "true") {
          setTimeout(() => navigate(`/welcome`, {replace: true}), 0)
        }else {
          setTimeout(() => navigate(`/s/${localStorage.getItem("scooter")}`, {replace: true}), 0)
        }
        
      }else {
        setTimeout(() => navigate(`/welcome`, {replace: true}), 0);
      }
    }
  }

  render() {
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}


export default withRouter(Authorization);
