import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button2 from "../../../../Common/Button/Button2";
import { dataAction } from "../../../../Redux/Data/Data.slice";
const EndTripConfirm:FC<any> = ({}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {trip} = useSelector((state: any) => state.data);

  const handleEndTrip = () => {
    dispatch(dataAction.setTripData({...trip}))
    navigate("/picture", {replace: true})
  }

  return (
    <>
      <Button2 label={t("end_trip.end_trip")} handleClick={handleOpen} color="#FA4E3E" width="80%" />
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <div className="text-center">
              <h6 className="mb-5 text-lg font-normal text-[#000]">
              {t("end_trip.confirmation")} 
              </h6>
              <div className="flex flex-row justify-between pt-4 space-x-2">
                <div className="w-full text-white bg-[#000000] text-[11px] py-2 rounded-[5px]" onClick={handleEndTrip}>{t("end_trip.yes")}</div>
                <div className="w-full bg-white border-2 border-[#000000] text-[11px] py-2 rounded-[5px]" onClick={handleClose}>{t("end_trip.no")}</div>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EndTripConfirm;
