import axios from "axios";
import url from "../../Config/url.config";
import history from "../../Common/History/History";
import { getUserData, handleLogout } from "../../Pages/Verify/util/Verfiy.util";
import credentials from "../../Config/credentials.config";

const { idToken, accessToken } = getUserData();

const axiosInstance: any = axios.create({
  baseURL: url.baseUrl,
});
const userAxiosInstance: any = axios.create({
  baseURL: url.userBaseUrl,
});
const noAuthAxiosInstance: any = axios.create({
  baseURL: url.noAuth,
});

axiosInstance.defaults.headers["Authorization"] = idToken;
axiosInstance.defaults.headers["x-api-key"] = credentials.keyId;
axiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/welcome" || originalConfig.url !== "/verify") {
      if (err.response.status === 401) {
        handleLogout();
        history.push("/auth/sign-in");
      } else {
        handleLogout();
        history.push(`/s/${localStorage.getItem("scooter")}`);
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);

userAxiosInstance.defaults.headers["Authorization"] = accessToken;
userAxiosInstance.defaults.headers["x-api-key"] = credentials.userKeyId;
userAxiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/welcome" || originalConfig.url !== "/verify") {
      if (err.response.status === 401) {
        handleLogout();
        history.push("/auth/sign-in");
      } else {
        handleLogout();
        history.push(`/s/${localStorage.getItem("scooter")}`);
        return Promise.reject(err);
      }
    }
  }
);

noAuthAxiosInstance.defaults.headers["x-api-key"] = credentials.userKeyId;

const instance = {
  userAxiosInstance,
  axiosInstance,
  noAuthAxiosInstance,
};

export default instance;
