import { FC } from "react";
import { Marker } from "react-naver-maps";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
const win: any = window;
const navermaps = win?.naver?.maps;
const ScooterDetail: FC<{
  scanned: string;
  scooter: any;
  setSelectedScooter: Function;
  selectedScooter: any;
}> = ({ scanned, scooter, setSelectedScooter, selectedScooter }) => {
  return (
    <>
      <Marker
        position={new navermaps.LatLng(scooter?.lat, scooter?.lon)}
        icon={{
          content: scanned === scooter?.serial_number
          ? '<img src="../../../../assets/images/scooter.png" alt="" style="padding: 5px; border: 3px solid #FA4E3E; display: block; width: 50px; height: 50px; border-radius: 25px; ">'
          : '<img src="../../../../assets/images/scooter.png" alt="" style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 40px; height: 35px; left: 0px; top: 0px;">',
          size: new navermaps.Size(22, 35),
          anchor: new navermaps.Point(11, 35),
        }}
        clickable={true}
        title={scooter?.serial_number}
        onClick={() => setSelectedScooter(scooter ?? {})}
      />
      <Dialog
        onClose={() => setSelectedScooter()}
        open={
          selectedScooter?.serial_number
            ? selectedScooter?.serial_number === scooter?.serial_number
            : false
        }
        fullWidth
        maxWidth={"md"}
      >
        {/* <DialogTitle className="text-xs">Scooter details</DialogTitle> */}
        <List
          sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
          dense={true}
        >
          <ListItem>
            <ListItemText primary="Serial No" secondary={scooter?.serial_number} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Battery" secondary={scooter?.battery} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="state"
              secondary={
                scooter?.state === "available" ? (
                  <Chip label="Available" color="success" size="small" />
                ) : (
                  <Chip label="Not Available" color="error" size="small" />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Lock state"
              secondary={
                scooter?.lock_state === "lock" ? <LockIcon /> : <LockOpenIcon />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Scooter model"
              secondary={scooter?.scooter_model}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Tags"
              secondary={scooter?.tagging?.map((tag: any) => (
                <Chip label={tag?.tag} size="small" />
              ))}
            />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
};

export default ScooterDetail;
