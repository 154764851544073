import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import credentials from "../../Config/credentials.config";
import { getUserData } from "../../Pages/Verify/util/Verfiy.util";
import axios from "../Util/axios.util";

const name: string = "ride";


const createExtraActions: any = () => {
  return {
    rideStart: rideStart(),
    rideEnd: rideEnd(),
    reset: reset(),
  };


  function rideStart() {
    const { idToken } = getUserData();
    return createAsyncThunk(`${name}/start_trip`, async (data: any) =>
      axios.axiosInstance.get("ride_start", {
        headers: {
          Authorization: idToken,
          "x-api-key": credentials.keyId,
          serial_number: data.serialNumber,
          card_id: data.cardId,
        },
      })
    );
  }

  function rideEnd() {
    const { idToken } = getUserData();
    return createAsyncThunk(`${name}/end_trip`, async (data: any) =>
      axios.axiosInstance.get("ride_stop", {
        headers: {
          Authorization: idToken,
          "x-api-key": credentials.keyId,
          serial_number: data.serialNumber,
          scooter_extra_discount_id : null,
        },
      })
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...rideStart(),
    ...rideEnd(),
    ...reset(),
  };

  function rideStart() {
    const { pending, fulfilled, rejected }: any = extraActions.rideStart;
    return {
      [pending]: (state: any) => {
        state.rideStart = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.rideStart = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.rideStart = { error: action.error };
      },
    };
  }

  function rideEnd() {
    const { pending, fulfilled, rejected }: any = extraActions.rideEnd;
    return {
      [pending]: (state: any) => {
        state.rideEnd = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.rideEnd = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.rideEnd = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.rideStart = {};
        state.rideEnd = {};
      },
    };
  }
};

const initialState: any = {
   rideStart: {},
   rideEnd: {}
};

const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const rideSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const rideAction = {
  ...rideSlice.actions,
  ...extraActions,
};
export const rideReducer = rideSlice.reducer;
