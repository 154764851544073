import * as yup from "yup";

export type PaymentPropType = {};

export type BillingInfo = {
  "card_id": string
  "card_name": string,
  "card_code": string,
  "used_count": number,
  "status_id": number,
  "status": string
}

export const PaymentValidationSchema = yup.object().shape({
  card_number: yup.string().min(19, "min 19").max(20, 'max 20').required("Please enter your card number"),
  expiry: yup.string().min(7, "max 7").max(7, "max 7").required("Please add you're expiry date"),
  birth: yup.string().min(6, "max 7").max(6, "max 7").required("Please add you're birth date"),
  pwd_2digit: yup
    .string()
    .min(2, "minimum 2")
    .max(2, "maximum 2")
    .required("Please add you're password"),
});


export const findBillingInfo = (billingInfo: BillingInfo[], cardNumber: string) => {
    const last = cardNumber.split("-")[3];
    const foundBillingInfo = billingInfo.find(({card_id}) => card_id === last);
    return foundBillingInfo ?? {}
};


