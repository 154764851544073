import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { NaverHeader } from "./Location.util";

const createExtraActions: any = () => {
  return {
    search: search(),
    driving: driving(),
    reset: reset(),
  };

  function search() {
    const rootUrl = process.env.NODE_ENV === "production" ? "https://naveropenapi.apigw.ntruss.com" : ""
    return createAsyncThunk(`${name}/search`, async (key) =>
      axios.get(`${rootUrl}/map-geocode/v2/geocode?query=${key}&limit=5`, NaverHeader())
    );
  }

  function driving() {
    const rootUrl = process.env.NODE_ENV === "production" ? "https://naveropenapi.apigw.ntruss.com" : ""
    return createAsyncThunk(`${name}/driving`, async (data: {start: string, goal: string}) =>
      axios.get(`${rootUrl}/map-direction/v1/driving?start=${data?.start}&goal=${data?.goal}&option=trafast`, NaverHeader())
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...search(),
    ...driving(),
    ...reset()
  };

  function search() {
    const { pending, fulfilled, rejected }: any = extraActions.search;
    return {
      [pending]: (state: any) => {
        state.search = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.search = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.search = { error: action.error };
      },
    };
  }

  function driving() {
    const { pending, fulfilled, rejected } = extraActions.driving;
    return {
      [pending]: (state: any) => {
        state.driving = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.driving = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.driving = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.search = null;
        state.driving = {};
      },
    };
  }


};

const initialState: any = {
  initiate: {},
};
const name: any = "location";
const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const locationSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const locationAction = { ...locationSlice.actions, ...extraActions };
export const locationReducer = locationSlice.reducer;
