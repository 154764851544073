import { FC, useState } from "react";
import Title from "../../Common/Typography/Title";
import MainContainer from "../../Container/Main.container";
import { formatPhoneNumber, setUserData, VerifyPropType } from "./util/Verfiy.util";
import CodeInput from "../../Common/CodeInput/CodeInput";
import CodeTimeOut from "../../Common/CodeTimeout/CodeTimeout";
import Button from "../../Common/Button/Button2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/User/User.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import { billingInfoAction } from "../../Redux/BillingInfo/BillingInfo.slice";
import { useTranslation } from "react-i18next";
import { ScooterAction } from "../../Redux/Scooter/Scooter.slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const VerifyPage: FC<VerifyPropType> = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { initiate, verify, userInfo } = useSelector(
    (state: any) => state.user
  );
  const [code, setCode] = useState<string>("");

  const handleVerify = () => {
    dispatch(
      userAction.verify({
        session: initiate?.Session,
        code,
        phone: initiate?.ChallengeParameters?.phone_number,
      })
    )
      .then((res: any) => {
        if (res?.payload?.AuthenticationResult?.IdToken) {
          setUserData(res?.payload?.AuthenticationResult);
          dispatch(ScooterAction.getScooter("ADSG008"));
          dispatch(billingInfoAction.getBillingInfo());
          dispatch(userAction.userInfo())
            .then(({ payload }: any) => {
              const responseData = payload?.data?.data ?? {};
              if (responseData?.blocked?.reason) {
                navigate("/blocked", { replace: true });
              } else if (responseData?.ordering?.no_paid?.length > 0) {
                navigate("/unpaid", { replace: true });
              } else {
                if (responseData?.billing_info?.length > 0) {
                  navigate("/saved-payment", { replace: true });
                }else {
                  navigate("/payment", { replace: true });
                }
              }
            })
            .catch((err: any) => {
              navigate("/", { replace: true });
            });
        } else {
          dispatch(
            snackActions.show({ message: "Code not valid", type: "error" })
          );
          navigate("/welcome", { replace: true });
        }
      })
      .catch((err: any) => {
        dispatch(
          snackActions.show({ message: "Verification failed", type: "error" })
        );
        navigate("/welcome", { replace: true });
      });
  };

  const resend = () => {
    // dispatch(userAction.initiate(initiate?.ChallengeParameters?.phone_number)).then((res: any) => {
    //   dispatch(snackActions.show({message: "Code sent", type: "success"}))
    // }).catch((err: any) => {
    //   dispatch(snackActions.show({message: "Code is not sent", type: "error"}))
    // })
    navigate("/welcome", { replace: true });
  };

  const phone = initiate?.ChallengeParameters?.phone_number;

  return (
    <MainContainer>
      <div className="absolute top-0 w-full">
        <div className="flex flex-row items-center justify-start h-[50px] bg-[#F2F2F2] w-full px-4">
          <div className="flex flex-row" onClick={() => navigate("/welcome", {replace: true})}>
            <ArrowBackIcon fontSize="small" />
            <p className="text-[14px] pl-4">{``} {formatPhoneNumber(phone)}</p>
          </div>
        </div>
      </div>
      <img src="/assets/images/smartphone.png" alt="Electric bike" />
      <div className="pt-8">
        <Title label={t("verify.verify")} variant="medium" />
      </div>
      <div className="pt-12 mb-32">
        <CodeInput code={code} setCode={setCode} />
        <CodeTimeOut resend={resend} />
      </div>
      <div className="absolute bottom-4 w-[350px] px-8">
        <Button
          label={t("verify.button")}
          loading={verify?.loading || userInfo?.loading}
          disabled={code.length !== 6 || initiate?.loading}
          handleClick={() => handleVerify()}
          color="#FA4E3E"
        />
      </div>
    </MainContainer>
  );
};

export default VerifyPage;
