import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

export const CardNumberMask = forwardRef(function TextMaskCustom(
  props: any,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000-0000-0000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    /> 
  );
});

export const PhoneNumberMask = forwardRef(function TextMaskCustom(
  props: any,
  ref: any
) {
  const { onChange, value, ...other } = props;
  return (
    <IMaskInput  
      {...other}
      mask={[{mask: "00-0000-0000"}, {mask: "000-0000-0000"}]}
      definitions={{
        "#": /[0-9]/, 
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      unmask={true}
    />
  );
});    

// 01012345678
// 1012345678

export const ExpirationDateMask = forwardRef(function TextMaskCustom(
  props: any,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000-00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const BirthDateMask = forwardRef(function TextMaskCustom(
  props: any,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});


