import { FC } from "react";
import { ContainerPropType } from "./util/Container.util";
// main screen layout container
const MainContainer: FC<ContainerPropType> = ({children, width}) => {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className={`flex flex-col items-center justify-center w-[${width ?? "350px"}] px-8`}>
            {children}
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
