import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createContext, FC, useEffect, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { Marker, NaverMap, Polygon } from "react-naver-maps";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";

import moment from "moment";
import { useTranslation } from "react-i18next";
import RenderMap from "../../Common/Map/RenderMap";
import { ServiceArea } from "../../Redux/ServiceArea/ServiceArea.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import EndTripConfirm from "./Component/Confirm/EndTrip.confirm";
import ScooterDetail from "./Component/Modal/ScooterDetail.modal";
import {
  formatServiceArea,
  formatTime,
  SearchContextInterface,
  SelectedOptionType,
  TripPagePropType
} from "./util/Trip.util";

export const SearchContext = createContext<SearchContextInterface>({
  selectedOption: {},
  setSelectedOption: () => {},
});

const win: any = window;
const navermaps = win?.naver?.maps;

const TripPage: FC<TripPagePropType> = () => {
  const { t } = useTranslation();
  let mapRef: any = useRef();

  const dispatch = useDispatch();
  const { seconds, minutes, hours, reset, } = useStopwatch({
    autoStart: true,
  });
  const [selectedScooter, setSelectedScooter] = useState<any>();
  const { userInfo } = useSelector((state: any) => state.user);
  const { scooter } = useSelector((state: any) => state.data);
  const [selectedOption, setSelectedOption] = useState<SelectedOptionType>({});
  const { noAuthServiceArea } = useSelector((state: any) => state.serviceArea);
  const { scooters } = useSelector((state: any) => state.scooter);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
        timeout: Infinity,
      },
      watchPosition: true,
      isOptimisticGeolocationEnabled: true,
    });

  useEffect(() => {
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(
      stopwatchOffset.getSeconds() + moment().diff(moment.utc(userInfo?.data?.data?.ordering?.start_time).local(), "seconds")
    );
    reset(stopwatchOffset);
  }, []);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <SearchContext.Provider
      value={{
        selectedOption,
        setSelectedOption,
      }}
    >
      {}
      <div className="w-full h-full">
        <div
          style={{
            position: "absolute",
            top: 10,
            zIndex: 1,
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="w-full">
            <div className="flex justify-center items-center">
              <div className="w-8/12 border-[3px] border-input-borderColor px-16 py-4 flex flex-col justify-center items-center bg-input-background rounded-md">
                <div className="text-[18px] font-bold">
                  {t("end_trip.in_trip")}
                </div>
                <div className="text-[20px] pt-[5px]">
                  {formatTime(hours)} : {formatTime(minutes)} :{" "}
                  {formatTime(seconds)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            zIndex: 1,
            width: "100%",
            textAlign: "center",
          }}
        >
          <EndTripConfirm />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#000",
            zIndex: 0,
          }}
        >
          <RenderMap
            isGeolocationAvailable={isGeolocationAvailable}
            isGeolocationEnabled={isGeolocationEnabled}
            coords={coords}
          >
            <div>
              <NaverMap
                naverRef={(ref: any) => {
                  mapRef = ref;
                }}
                mapDivId={"maps-getting-started-uncontrolled"}
                style={{
                  width: "100%",
                  height: "100vh",
                }}
                defaultCenter={{
                  lat: scooter?.lat ?? coords?.latitude,
                  lng: scooter?.lon ?? coords?.longitude,
                }}
                defaultZoom={15}
                zoom={15}
                logoControl={false}
              >
                <Marker
                  position={
                    new navermaps.LatLng(coords?.latitude, coords?.longitude)
                  }
                  title="Your location"
                />
                {scooters?.data?.errorCode === 0 &&
                  (scooters?.data?.data ?? []).map((scooterItem: any) => (
                    <ScooterDetail
                      scanned={scooter?.serial_number ?? ""}
                      scooter={scooterItem}
                      setSelectedScooter={setSelectedScooter}
                      selectedScooter={selectedScooter}
                    />
                  ))}

                {selectedOption?.latitude && (
                  <Marker
                    position={
                      new navermaps.LatLng(
                        selectedOption?.latitude,
                        selectedOption?.longitude
                      )
                    }
                    title={selectedOption?.location}
                  />
                )}

                <Polygon
                  paths={[
                    [
                      new navermaps.LatLng(90, -180),
                      new navermaps.LatLng(90, 180),
                      new navermaps.LatLng(-90, 180),
                      new navermaps.LatLng(-90, -180),
                    ],
                    ...formatServiceArea(noAuthServiceArea).type0serviceAreaArr,
                  ]}
                  fillColor={"#000000"}
                  fillOpacity={0.6}
                  strokeColor="#fff"
                  strokeOpacity={0.6}
                  strokeWeight={3}
                />

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 5)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#8A7FFF"}
                        fillOpacity={0.6}
                        strokeColor="#8A7FFF"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: `${area.description}`,
                              type: "error",
                              sub: `${area?.amount ?? 0} 원 추가`,
                              isCustom: true,
                              color: "#8A7FFF",
                            })
                          );
                        }}
                      />
                    );
                  })}

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 6)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#EB7B20"}
                        fillOpacity={0.6}
                        strokeColor="#EB7B20"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: area.description,
                              type: "error",
                              isCustom: true,
                              color: "#EB7B20",
                            })
                          );
                        }}
                      />
                    );
                  })}

                {(noAuthServiceArea?.data?.data ?? [])
                  .filter(({ type }: any) => type === 1)
                  .map((area: ServiceArea, index: number) => {
                    const arr2: any = (area?.available ?? []).map(
                      (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                    );
                    return (
                      <Polygon
                        paths={[arr2]}
                        fillColor={"#E84725"}
                        fillOpacity={0.6}
                        strokeColor="#E84725"
                        strokeOpacity={0.8}
                        strokeWeight={1}
                        clickable={true}
                        onClick={() => {
                          dispatch(
                            snackActions.show({
                              message: area.description,
                              type: "error",
                              isCustom: true,
                              color: "#E84725",
                            })
                          );
                        }}
                      />
                    );
                  })}
              </NaverMap>
            </div>
          </RenderMap>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={noAuthServiceArea?.loading || scooters?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SearchContext.Provider>
  );
};

export default TripPage;
