import { useEffect, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { NaverMap, Polygon } from "react-naver-maps";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Common/Button/Button2";
import { useTranslation } from "react-i18next";
import MySnackbarContent from "./components/Custom.snack";
import RenderMap from "../../Common/Map/RenderMap";
import { useDispatch } from "react-redux";
import { ScooterAction } from "../../Redux/Scooter/Scooter.slice";
import { dataAction } from "../../Redux/Data/Data.slice";
import {
  ServiceArea,
  ServiceAreaAction,
} from "../../Redux/ServiceArea/ServiceArea.slice";
import ScooterDetail from "../Trip/Component/Modal/ScooterDetail.modal";
import { formatServiceArea } from "../Trip/util/Trip.util";
import { saveScooter } from "../Language/util/Language.util";

const ScooterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const { scooters } = useSelector((state: any) => state.scooter);
  const { noAuthServiceArea } = useSelector((state: any) => state.serviceArea);
  const [selectedScooter, setSelectedScooter] = useState<any>();

  let mapRef: any = useRef();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
      },
      isOptimisticGeolocationEnabled: true,
      onError: (err) => console.log(err),
    });

  const win: any = window;
  const navermaps = win?.naver?.maps;

  const [showSnack, setShowSnack] = useState(false);
  useEffect(() => {
    if (coords?.latitude) {
      dispatch(
        ScooterAction.getScooters({
          longitude: coords?.longitude,
          latitude: coords?.latitude,
          distance: 2,
        })
      );
    }
  }, [coords]);

  useEffect(() => {
    if (params.scooterId) {
      saveScooter(params.scooterId);
    }
  }, [params]);


  useEffect(() => {
    dispatch(ServiceAreaAction.getAllNoAuthServiceArea());
  }, []);


  const handleShowSnack = () => {
    setShowSnack(true);
  };

  const buttonDisabled = !(scooters?.data?.data ?? []).find(
    (item: any) => item.serial_number === params.scooterId
  );

  const handleNext = () => {
    const selectedScooter = (scooters?.data?.data ?? []).find(
      (item: any) => item.serial_number === params.scooterId
    );
    saveScooter(params.scooterId!)
    dispatch(dataAction.setScooterData(selectedScooter));
    navigate("/language");
  };

  const ss = (scooters?.data?.data ?? []).find(
    (item: any) => item.serial_number === params.scooterId
  );

  const handleSetBack = () => {
    const loc = new navermaps.LatLng(
      ss?.lat ?? coords?.latitude,
      ss?.lon ?? coords?.longitude
    );
    mapRef.setCenter(loc);
    mapRef.setZoom(16);
  };

  useEffect(() => {
    if (ss?.lat && ss?.lon) {
      const loc = new navermaps.LatLng(
        ss?.lat ?? coords?.latitude,
        ss?.lon ?? coords?.longitude
      );
      mapRef.setCenter(loc);
      mapRef.setZoom(16);
    }
  }, [ss]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: 10,
          zIndex: 1,
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          className="w-full flex justify-end p-4 cursor-pointer"
          onClick={handleSetBack}
        >
          <img
            src="/assets/icon/placeholder.svg"
            alt="location"
            className="w-1/12"
          />
        </div>
        {buttonDisabled ? (
          <div onClick={handleShowSnack}>
            <Button
              label={t("common.button.continue")}
              handleClick={() => handleNext()}
              disabled={buttonDisabled}
              color="#FA4E3E"
              width="80%"
            />
          </div>
        ) : (
          <Button
            label={t("common.button.continue")}
            handleClick={() => handleNext()}
            disabled={buttonDisabled}
            color="#FA4E3E"
            width="80%"
          />
        )}
      </div>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#000",
          zIndex: 0,
        }}
      >
        <RenderMap
          isGeolocationAvailable={isGeolocationAvailable}
          isGeolocationEnabled={isGeolocationEnabled}
          coords={coords}
        >
          <div>
            <NaverMap
              naverRef={(ref: any) => {
                mapRef = ref;
              }}
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              defaultCenter={{
                lat: ss?.lat ?? coords?.latitude,
                lng: ss?.lon ?? coords?.longitude,
              }}
              defaultZoom={15}
              zoom={15}
              zoomControl={false}
              logoControl={false}
            >
              {scooters?.data?.errorCode === 0 &&
                (scooters?.data?.data ?? []).map((scooter: any) => (
                  <ScooterDetail
                    scanned={params?.scooterId ?? ""}
                    scooter={scooter}
                    setSelectedScooter={setSelectedScooter}
                    selectedScooter={selectedScooter}
                  />
                ))}

              <Polygon
                paths={[
                  [
                    new navermaps.LatLng(90, -180),
                    new navermaps.LatLng(90, 180),
                    new navermaps.LatLng(-90, 180),
                    new navermaps.LatLng(-90, -180),
                  ],
                  ...formatServiceArea(noAuthServiceArea).type0serviceAreaArr,
                ]}
                fillColor={"#000000"}
                fillOpacity={0.6}
                strokeColor="#fff"
                strokeOpacity={0.6}
                strokeWeight={3}
              />

              {(noAuthServiceArea?.data?.data ?? [])
                .filter(({ type }: any) => type === 5)
                .map((area: ServiceArea, index: number) => {
                  const arr2: any = (area?.available ?? []).map(
                    (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                  );
                  return (
                    <Polygon
                      paths={[arr2]}
                      fillColor={"#8A7FFF"}
                      fillOpacity={0.6}
                      strokeColor="#8A7FFF"
                      strokeOpacity={0.8}
                      strokeWeight={1}
                      clickable={true}
                    />
                  );
                })}

              {(noAuthServiceArea?.data?.data ?? [])
                .filter(({ type }: any) => type === 6)
                .map((area: ServiceArea, index: number) => {
                  const arr2: any = (area?.available ?? []).map(
                    (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                  );
                  return (
                    <Polygon
                      paths={[arr2]}
                      fillColor={"#EB7B20"}
                      fillOpacity={0.6}
                      strokeColor="#EB7B20"
                      strokeOpacity={0.8}
                      strokeWeight={1}
                      clickable={true}
                    />
                  );
                })}

              {(noAuthServiceArea?.data?.data ?? [])
                .filter(({ type }: any) => type === 1)
                .map((area: ServiceArea, index: number) => {
                  const arr2: any = (area?.available ?? []).map(
                    (pa: any) => new navermaps.LatLng(pa.lat, pa.lon)
                  );
                  return (
                    <Polygon
                      paths={[arr2]}
                      fillColor={"#E84725"}
                      fillOpacity={0.6}
                      strokeColor="#E84725"
                      strokeOpacity={0.8}
                      strokeWeight={1}
                      clickable={true}
                    />
                  );
                })}
            </NaverMap>
          </div>
        </RenderMap>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={scooters?.loading || noAuthServiceArea?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MySnackbarContent
        show={showSnack}
        setShow={setShowSnack}
        message="TEST"
      />
    </>
  );
};

export default ScooterPage;
