import moment, { Moment } from "moment";

export type SummaryPagePropType = {};

const formatDateString = (val: number, duration: string) =>
  `${val === 1 ? (duration === "hour" ? "An" : "A") : val} ${duration}${
    val !== 1 ? "s" : ""
  } ago`;

export const formatDistance = (startDate: Moment, endDate: Moment) => {
  const sd = moment.utc(startDate).local();
  const ed = moment.utc(endDate).local();
  const years = ed.diff(sd, "years");
  const month = ed.diff(sd, "months");
  const day = ed.diff(sd, "days");
  const hour = ed.diff(sd, "hours");
  const minute = ed.diff(sd, "minutes");
  const seconds = ed.diff(sd, "seconds");
  if (years !== 0) return formatDateString(years, "year");
  if (month !== 0) return formatDateString(month, "month");
  if (day !== 0) return formatDateString(day, "day");
  if (hour !== 0) return formatDateString(hour, "hour");
  if (minute !== 0) return formatDateString(minute, "minute");
  if (seconds !== 0) return formatDateString(seconds, "second");
  return { sd, ed };
};

export const dateDiffToString = (startDate: Moment, endDate: Moment) => {
  var duration = moment.duration(endDate.diff(startDate));
  var minutes = parseInt(duration.asMinutes().toString(), 10) % 60;
  var seconds = parseInt(duration.asSeconds().toString(), 10) % 3600;
  if (minutes === 0) return `${seconds} secs`;
  return minutes + "mins " + seconds + "secs";
};

export const formatDateDifference = (startDate: Moment, endDate: Moment) => {
  const seconds = startDate.diff(endDate, "seconds");
  return seconds
};

export const formatToCurrency = (amount: number) => {
  return amount === 0 ? "0.00" : (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
}

export const convertHMS = (sec: number) =>  {
  const formattedDate = []
  const hours   = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = sec - (hours * 3600) - (minutes * 60);
  if (hours !== 0) formattedDate.push(`${hours}hrs`);
  if (minutes !== 0) formattedDate.push(`${minutes}mins`);
  if (seconds !== 0) formattedDate.push(`${seconds}secs`);
  return formattedDate.join(" ");
}