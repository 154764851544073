export type LanguagePropType = {};

export const saveScooter = (scooter: string) => {
    localStorage.setItem("scooter", scooter);
    localStorage.setItem("is_done", "false");
};

export const getScooter = () => {
    const scooter = localStorage.getItem("scooter");
    return scooter;
}