import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import credentials from "../../Config/credentials.config";
import { getUserData } from "../../Pages/Verify/util/Verfiy.util";
import axios from "../Util/axios.util";

const name: string = "ordering";

const createExtraActions: any = () => {
  return {
    confirmPayment: confirmPayment(),
    reset: reset(),
  };

  function confirmPayment() {
    const { idToken } = getUserData();
    return createAsyncThunk(`${name}/confirm`, async (id: number, data: any) =>
      axios.userAxiosInstance.put(
        `${name}/${id}`,
        {},
        {
          headers: {
            Authorization: idToken,
            "x-api-key": credentials.keyId,
            card_id: data.cardId,
          },
        }
      )
    );
  }

  function reset() {
    return createAction(`${name}/reset`);
  }
};

const createExtraReducers: any = () => {
  return {
    ...confirmPayment(),
    ...reset(),
  };

  function confirmPayment() {
    const { pending, fulfilled, rejected }: any = extraActions.confirmPayment;
    return {
      [pending]: (state: any) => {
        state.confirmPayment = { loading: true };
      },
      [fulfilled]: (state: any, action: any) => {
        state.confirmPayment = action.payload;
      },
      [rejected]: (state: any, action: any) => {
        state.confirmPayment = { error: action.error };
      },
    };
  }

  function reset() {
    return {
      [extraActions.reset]: (state: any) => {
        state.confirmPayment = {};
      },
    };
  }
};

const initialState: any = {
  confirm: {},
};

const extraActions: any = createExtraActions();
const extraReducers: any = createExtraReducers();
const orderingSlice: any = createSlice({
  name,
  initialState,
  extraReducers,
  reducers: {},
});

export const orderingAction = {
  ...orderingSlice.actions,
  ...extraActions,
};
export const orderingReducer = orderingSlice.reducer;
