import {
  Backdrop, Box, Checkbox,
  CircularProgress
} from "@mui/material";
import { Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Common/Button/Button2";
import { PhoneNumberMask } from "../../Common/Input/Masks/Payment.mask";
import MainContainer from "../../Container/Main.container";
import { billingInfoAction } from "../../Redux/BillingInfo/BillingInfo.slice";
import { dataAction } from "../../Redux/Data/Data.slice";
import { locationAction } from "../../Redux/Location/Location.slice";
import { orderingAction } from "../../Redux/Ordering/Ordering.slice";
import { parkingAction } from "../../Redux/Parking/Parking.slice";
import { useAppDispatch } from "../../Redux/Store";
import { userAction } from "../../Redux/User/User.slice";
import { snackActions } from "../../Redux/Util/Snack.slice";
import { StyledTextField } from "../Payment/Payment.page";
import {
  formatPhone,
  PhoneValidationSchema,
  WelcomePagePropType
} from "./util/Welcome.util";
const WelcomePage: FC<WelcomePagePropType> = ({}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const { initiate, signup } = useSelector((state: any) => state.user);
  const scooterData = useSelector((state: any) => state.scooter);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    // handleLogout();
    // clearTripData();
    dispatch(dataAction.reset());
    dispatch(billingInfoAction.reset());
    dispatch(parkingAction.reset());
    dispatch(orderingAction.reset());
    dispatch(userAction.reset());
    dispatch(locationAction.reset());
  }, []);

  const handleSubmit = (phone: string) => {
    const formattedPhone = formatPhone(phone.replaceAll("-", ""));
    dispatch(userAction.signup(formattedPhone))
      .then((res: any) => {
        dispatch(userAction.initiate(formattedPhone))
          .then(({ payload }: any) => {
            navigate("/verify", { replace: true });
          })
          .catch((initError: any) => {
            dispatch(
              snackActions.show({
                message: "Couldn't send code",
                type: "error",
              })
            );
          });
      })
      .catch((err: any) => {
        if (err?.error?.name === "UsernameExistsException") {
          dispatch(userAction.initiate(formattedPhone))
            .then(({ payload }: any) => {
              navigate("/verify", { replace: true });
            })
            .catch((initError: any) => {
              dispatch(
                snackActions.show({
                  message: "Couldn't send code",
                  type: "error",
                })
              );
            });
        } else {
          dispatch(
            snackActions.show({ message: "Error occured", type: "error" })
          );
        }
      });
  };

  return (
    <MainContainer>
      <>
        <img
          src="/assets/images/flowerroad_logo@3x.png"
          width={90}
          alt="Flower road logo"
        />
        <p className="text-[25px] text-black font-extrabold pt-4 mb-[8px]">
          {t("welcome.welcome")}
        </p>
        <Formik
          initialValues={{ phone: "" }}
          validationSchema={PhoneValidationSchema}
          onSubmit={(values) => handleSubmit(values.phone)}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className="w-full">
                  <p className="text-lg pb-2">
                    {t("welcome.verify")}
                  </p>
                  <div className="flex flex-row justify-center items-center w-full h-full">
                    <StyledTextField
                      name="phone"
                      size="small"
                      className="w-full"
                      value={values.phone}
                      onChange={(pj) => setFieldValue("phone", pj.target.value)}
                      error={touched.phone && Boolean(errors.phone)}
                      placeholder="xxx-xxxx-xxxx"
                      helperText={touched.phone && errors.phone}
                      inputProps={{ inputMode: 'numeric' }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: 0,
                          backgroundColor: "#F2F2F2",
                        },
                      }}
                      InputProps={{
                        inputComponent: PhoneNumberMask,
                        startAdornment: (
                          <>
                            <Box
                              onClick={(e: any) => handleClick(e)}
                              sx={{
                                backgroundColor: "#F2F2F2",
                                padding: 1.2,
                                borderRightWidth: "3px",
                                borderRightColor: "#D8D8D8",
                                marginRight: 1,
                              }}
                            >
                              <div className="flex flex-row space-x-[2px] text-sm font-bold">
                                <p>KR</p>
                                <p>(+82)</p>
                              </div>
                            </Box>
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
                <>
                  <div className="flex justify-between items-center flex-row pt-4 mb-20 space-x-[3px]">
                    <Checkbox
                      checked={checked}
                      onClick={(e) => setChecked(!checked)}
                      className="p-0 m-0"
                      checkedIcon={
                        <>
                          <div className="w-4 h-4 border-[2px] border-[#D8D8D8] bg-[#F2F2F2] rounded-[3px]" />
                          <div className="absolute left-3 bottom-3">
                            <img
                              src="/assets/images/checkmark.svg"
                              width={25}
                              alt="check mark"
                            />
                          </div>
                        </>
                      }
                      icon={
                        <div className="w-4 h-4 border-[2px] border-[#D8D8D8] bg-[#F2F2F2] rounded-[3px]" />
                      }
                    />
                    {i18n.language === "en" ? (
                      <div className="text-[11px]">
                        {t("welcome.terms.one")}{" "}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/terms.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.two")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/rent.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.three")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/location.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.four")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="insurance-policy"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.five")}
                        </a>{" "}
                        {t("common.and")}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/privacy.html"
                          target="_blank"
                          className="text-main cursor-pointer pl-1"
                        >
                          {t("welcome.terms.six")}
                        </a>
                      </div>
                    ) : (
                      <div className="text-[11px]">
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/terms.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.two")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/rent.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.three")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/location.html"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.four")}
                        </a>
                        {", "}
                        <a
                          rel="noreferrer"
                          href="insurance-policy"
                          target="_blank"
                          className="text-main cursor-pointer"
                        >
                          {t("welcome.terms.five")}
                        </a>{" "}
                        {t("common.and")}
                        <a
                          rel="noreferrer"
                          href="http://www.flowerroad.ai/privacy.html"
                          target="_blank"
                          className="text-main cursor-pointer pl-1"
                        >
                          {t("welcome.terms.six")}
                        </a>
                        {" "}
                        등의 약관을 확인하였으며, 이에 동의합니다.

                      </div>
                    )}
                  </div>
                </>
                <div className="absolute bottom-4 w-[350px] px-8">
                  <Button
                    label={t("welcome.send_code")}
                    disabled={
                      (errors.phone || values.phone === "" ? true : false) ||
                      !checked ||
                      initiate?.loading ||
                      signup?.loading
                    }
                    loading={initiate?.loading || signup?.loading}
                    handleClick={() => handleSubmit()}
                    color="#FA4E3E"
                  />
                </div>
              </>
            );
          }}
        </Formik>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={scooterData?.scooter?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
      {/* )} */}
    </MainContainer>
  );
};

export default WelcomePage;
