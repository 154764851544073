import WarningIcon from '@mui/icons-material/Warning';
import Snackbar from "@mui/material/Snackbar";
import { FC, useEffect } from "react";

const MySnackbarContent: FC<any> = (props) => {
  const { show, setShow } = props;

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [show]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <div className="flex flex-row justify-center items-center border-[3px] border-[#D8D8D8] p-2 mx-8 bg-[#F2F2F2] rounded-md">
        <div className="w-2/12">
            <WarningIcon sx={{color: "red"}} />
        </div>
        <div className="w-10/12">
            <p className="text-[13px]">Please scan a QR code from one of our scooters nearby.</p>
        </div>
      </div>
    </Snackbar>
  );
};

export default MySnackbarContent;
