import { styled } from "@mui/material/styles";
import { FC } from "react";
import { CustomButtonPropTypes } from "./Button.util";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";



const Button: FC<CustomButtonPropTypes> = ({
  label,
  disabled,
  loading,
  handleClick,
  variant,
  size,
  type,
  color,
  width
}) => {
  const handleButton = () => {
    if (disabled) return () => {};
    if (handleClick) return handleClick();
    return undefined
  };

  const CustomButton = styled(LoadingButton)((props) => {
    return {
      boxShadow: "none",
      textTransform: "none",
      borderRadius: 5,
      fontSize: "22px",
      fontWeight: 500,
      padding: "5px 35px",
      backgroundColor: color,
      borderColor: color,
      "&:hover": {
        backgroundColor: color,
        borderColor: color,
        boxShadow: "none",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: color,
        borderColor: color,
      },
      "&:disabled": {
        backgroundColor: "#999999",
        color: loading ? "#999999" : "white"
      },

    }
  });

  return (
    <CustomButton
      sx={{width: width}}
      variant={variant}
      disableRipple
      disabled={disabled}
      loading={loading}
      type={type ?? "button"}
      onClick={handleButton}
      size={size}
      loadingIndicator={<CircularProgress sx={{color: "white"}} size={16} />}
    >
      {label}
    </CustomButton>
  );
};

Button.defaultProps = {
  color: "#277BC0",
  width: "100%",
  size: "medium",
  variant: "contained",
}

export default Button;
