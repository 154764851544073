import "react-phone-input-2/lib/style.css";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-phone-input-2/lib/style.css";
import { Provider } from "react-redux";
import SnackBar from "./Common/SnackBar/SnackBar";
import "./index.css";
import store from "./Redux/Store";
import Routing from "./Utils/Routes";
import { I18nextProvider } from "react-i18next";
import i18n from "./Utils/Localization/i18n";
import Authorization from "./Utils/Authorization";

function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"kr"}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <BrowserRouter>
              <Authorization>
                  <Routing />
              </Authorization>
            </BrowserRouter>
            <SnackBar />
          </Provider>
        </I18nextProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
