import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { snackActions } from "../../Redux/Util/Snack.slice";
import { SnackBarPropType } from "./util/SnackBar.util";
import WarningIcon from "@mui/icons-material/Warning";

const Alert: FC<any> = React.forwardRef(function Alert(props, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar: FC<SnackBarPropType> = () => {
  const dispatch = useDispatch();
  const { show, message, type, sub, isCustom, color } = useSelector(
    (state: any) => state.snack
  );

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackActions.hide());
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch(snackActions.hide());
      }, 2000);
    }
  }, [show, dispatch]);

  if (isCustom)
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={show}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <div
          className={`flex flex-row justify-center items-center border-[2px] border-white p-2 mx-12 text-white rounded-md`}
          style={{ backgroundColor: color }}
        >
          <div className="flex flex-col items-center justify-start">
            <p>{message ?? "Default message"}</p>
            <p>{sub ?? ""}</p>
          </div>
        </div>
      </Snackbar>
    );
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={show}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        {type ? (
          <Alert
            onClose={handleClose}
            severity={type}
            sx={
              type === "warning"
                ? { width: "100%", backgroundColor: "#fd3200" }
                : { width: "100%" }
            }
          >
            <div className="flex flex-col items-center justify-start">
              <p>{message ?? "Default message"}</p>
              <p>{sub ?? ""}</p>
            </div>
          </Alert>
        ) : (
          <p />
        )}
      </Snackbar>
    </Stack>
  );
};

export default SnackBar;
