import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../Common/Button/Button2";
import MainContainer from "../../Container/Main.container";

const PicturePage = () => {
  const {t} = useTranslation() 
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="flex flex-col justify-center items-center pb-32">
        <img src="/assets/images/camera@3x.png" width={80} alt="Electric bike" />
        <div className="text-center pt-8 text-[#999999]">
          <p className="text-xl">{t("parking.confirmation")}</p>
        </div>
      </div>
      <div className="absolute bottom-4 w-[350px] px-8">
        <Button label={t("parking.take_picture")} handleClick={() => navigate("/camera-feed")} color="#FA4E3E" />
      </div>
    </MainContainer>
  );
};

export default PicturePage;
